.menu{
  position: fixed;
  padding: 0;
  height: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 70px;
  transition: all 0.5s;

  .menu__wrapper{
    z-index: 1;
    margin-top: 50px;
    transition: all 0.3s;

    nav{
      font-size: $font-size-t5;
      line-height: $line-height-t5;
      font-family: $font-family-title;
      text-transform: uppercase;
      font-weight: 700;

      a{
        color: $color-text-light;
        opacity: 1;

        &:hover{
          opacity: 0.5;
        }

        &:after{
          background: $color-text-light;
        }
      }
    }
  }

  .menu__mobile-button{
    color: $color-text-light;
    opacity: 0.5;
    transition: all 0.3s;
    background-color: transparent;
    border: none;

    &:hover{
      opacity: 1;
    }
  }

  &.menu--active{
    //opacity: 0.9;
    background-color: #ffffff;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.14);

    .menu__wrapper{
      margin-top: 25px;

      a{
        color: $color-text-main !important;
        opacity: 1;

        &:hover{
          color: $color-accent !important;
        }
        &:after{
          background: $color-accent !important;
        }
      }
    }

    .menu__mobile-button{
      color: $color-text-main !important;
      opacity: 0.9;
      margin-top: -5px;
      transition: all 0.3s;
      background-color: transparent;
      border: none;

      &:hover{
        color: $color-accent !important;
        opacity: 1;
      }
      &:after{
        background: $color-accent !important;
      }
    }
  }
}