.portfolio-modal{
  .modal-content{
    border-radius: 0px;
    background-color: #f6f5f5;
  }

  .modal-header{
    border-bottom: 0px;
    height: 70px;

    @media screen and (max-width: 990px){
      height: 50px;
    }

    .close{
      right: 20px;
      top: 20px;
      position: absolute;
      font-weight: 300;
      color: $color-text-additional;
      font-size: 20px;
      width: 20px;
    }
  }

  .modal-body{
    padding: 0;
    padding-bottom: 80px;

    @media screen and (max-width: 990px){
      padding: 15px;
      padding-bottom: 50px;
    }
  }

  .portfolio-modal__title{
    font-family: $font-family-title;
    margin-bottom: 30px;
  }

  .portfolio-modal__img{
    width: 100%;
    margin-bottom: 30px;
  }

  .portfolio-modal__description{
    font-size: $font-size-t4;
    line-height: $line-height-t4;
    margin-bottom: 0px;
  }

  .portfolio-modal__link{
    font-family: $font-family-title;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    margin-top: 40px;
  }

  .portfolio-modal__stack{
    display: flex;
    margin-top: 40px;
  }

  .portfolio-modal__stack-title{
    font-family: $font-family-title;
    font-size: $font-size-t6;
    line-height: $line-height-t6;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 15px;
    padding-top: 10px;
  }
}