.project-card{
  margin-left: 0;
  margin-bottom: 40px;
  transition: box-shadow 0.3s;
  margin-right: 0;

  .project-card__img{
    padding-right: 0;
    padding-left: 0;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .project-card__info{
    border: 1px solid #f1f1f1;
    padding: 50px 50px 40px 30px;
  }

  .project-card__title{
    font-size: $font-size-t3;
    line-height: $line-height-t3;
    font-family: $font-family-title;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .project-card__description{
    font-size: $font-size-t4;
    line-height: $line-height-t4;
    margin-bottom: 45px;
  }

  .project-card__stack{
    font-family: $font-family-title;
    font-size: $font-size-t6;
    line-height: $line-height-t6;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .project-card__link{
    margin-top: 55px;
    font-family: $font-family-title;
    font-size: $font-size-t5;
    line-height: $line-height-t5;
    float: right;
  }

  &:hover{
    box-shadow: 0 1px 31px rgba(0,0,0,0.09);
    cursor: pointer;
  }
}
